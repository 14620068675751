import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _4d7712a8 = () => interopDefault(import('../src/pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _6ce3cb70 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _f5d9111e = () => interopDefault(import('../src/pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _a0d9c188 = () => interopDefault(import('../src/pages/initial-settings/company-profile.vue' /* webpackChunkName: "pages/initial-settings/company-profile" */))
const _6bb19842 = () => interopDefault(import('../src/pages/initial-settings/invite-members.vue' /* webpackChunkName: "pages/initial-settings/invite-members" */))
const _2254d7db = () => interopDefault(import('../src/pages/initial-settings/preferences.vue' /* webpackChunkName: "pages/initial-settings/preferences" */))
const _3da9abd4 = () => interopDefault(import('../src/pages/initial-settings/user-profile.vue' /* webpackChunkName: "pages/initial-settings/user-profile" */))
const _576288fe = () => interopDefault(import('../src/pages/sessions/callback.vue' /* webpackChunkName: "pages/sessions/callback" */))
const _09560fd5 = () => interopDefault(import('../src/pages/sessions/invitation.vue' /* webpackChunkName: "pages/sessions/invitation" */))
const _1e1013e4 = () => interopDefault(import('../src/pages/contents/_uuid/index.vue' /* webpackChunkName: "pages/contents/_uuid/index" */))
const _b5a870a0 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds" */))
const _87d2db7e = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds/new.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds/new" */))
const _40644cd0 = () => interopDefault(import('../src/pages/companies/_company_id/customers.vue' /* webpackChunkName: "pages/companies/_company_id/customers" */))
const _5be51fae = () => interopDefault(import('../src/pages/companies/_company_id/customers/new.vue' /* webpackChunkName: "pages/companies/_company_id/customers/new" */))
const _99f538f4 = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/index" */))
const _6c4d91f4 = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/edit" */))
const _4c9bd5fc = () => interopDefault(import('../src/pages/companies/_company_id/expiration.vue' /* webpackChunkName: "pages/companies/_company_id/expiration" */))
const _c32519c8 = () => interopDefault(import('../src/pages/companies/_company_id/members.vue' /* webpackChunkName: "pages/companies/_company_id/members" */))
const _557a6bac = () => interopDefault(import('../src/pages/companies/_company_id/profile.vue' /* webpackChunkName: "pages/companies/_company_id/profile" */))
const _8dea566c = () => interopDefault(import('../src/pages/companies/_company_id/projects/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/index" */))
const _3110b476 = () => interopDefault(import('../src/pages/companies/_company_id/reports/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/index" */))
const _439a44d3 = () => interopDefault(import('../src/pages/companies/_company_id/setting.vue' /* webpackChunkName: "pages/companies/_company_id/setting" */))
const _7c777a0a = () => interopDefault(import('../src/pages/companies/_company_id/sharing-contents/index.vue' /* webpackChunkName: "pages/companies/_company_id/sharing-contents/index" */))
const _f6ebf67e = () => interopDefault(import('../src/pages/companies/_company_id/unavailability.vue' /* webpackChunkName: "pages/companies/_company_id/unavailability" */))
const _e8731898 = () => interopDefault(import('../src/pages/companies/_company_id/user-profile.vue' /* webpackChunkName: "pages/companies/_company_id/user-profile" */))
const _2db15698 = () => interopDefault(import('../src/pages/companies/_company_id/projects/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/new" */))
const _ca84a8b0 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/index" */))
const _19fb81b2 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id" */))
const _51787ab5 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/index" */))
const _bfe220c0 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/index" */))
const _75e9eb7e = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/detail.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/detail" */))
const _63be1147 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/member.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/member" */))
const _9303033c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/reports.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/reports" */))
const _78223507 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/index" */))
const _278a2eee = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/new" */))
const _63e4f838 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/posts/index" */))
const _02aa3f54 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/fullscreen" */))
const _cb595c86 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/preview" */))
const _1db0ce65 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/print/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/print/index" */))
const _3ed441fa = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/index" */))
const _11c3d84c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/fullscreen" */))
const _7dd57784 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/index" */))
const _497cfcc7 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/index" */))
const _c250a970 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/edit" */))
const _7edcd26c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index" */))
const _6358f1a5 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/edit" */))
const _ffc9ad06 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/preview" */))
const _2a6353a0 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/index" */))
const _c0733ab8 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/edit" */))
const _f828b7c4 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/preview" */))
const _059b71c8 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/edit" */))
const _1c3a07d0 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/pdf" */))
const _d169711c = () => interopDefault(import('../src/pages/companies/_company_id/project/_id/schedule/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/project/_id/schedule/pdf" */))
const _26db085c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/companies",
      component: _4d7712a8,
      name: "companies"
    }, {
      path: "/logout",
      component: _6ce3cb70,
      name: "logout"
    }, {
      path: "/sign_in",
      component: _f5d9111e,
      name: "sign_in"
    }, {
      path: "/initial-settings/company-profile",
      component: _a0d9c188,
      name: "initial-settings-company-profile"
    }, {
      path: "/initial-settings/invite-members",
      component: _6bb19842,
      name: "initial-settings-invite-members"
    }, {
      path: "/initial-settings/preferences",
      component: _2254d7db,
      name: "initial-settings-preferences"
    }, {
      path: "/initial-settings/user-profile",
      component: _3da9abd4,
      name: "initial-settings-user-profile"
    }, {
      path: "/sessions/callback",
      component: _576288fe,
      name: "sessions-callback"
    }, {
      path: "/sessions/invitation",
      component: _09560fd5,
      name: "sessions-invitation"
    }, {
      path: "/contents/:uuid?",
      component: _1e1013e4,
      name: "contents-uuid"
    }, {
      path: "/companies/:company_id/construction-kinds",
      component: _b5a870a0,
      name: "companies-company_id-construction-kinds",
      children: [{
        path: "new",
        component: _87d2db7e,
        name: "companies-company_id-construction-kinds-new"
      }]
    }, {
      path: "/companies/:company_id/customers",
      component: _40644cd0,
      name: "companies-company_id-customers",
      children: [{
        path: "new",
        component: _5be51fae,
        name: "companies-company_id-customers-new"
      }, {
        path: ":id",
        component: _99f538f4,
        name: "companies-company_id-customers-id"
      }, {
        path: ":id/edit",
        component: _6c4d91f4,
        name: "companies-company_id-customers-id-edit"
      }]
    }, {
      path: "/companies/:company_id/expiration",
      component: _4c9bd5fc,
      name: "companies-company_id-expiration"
    }, {
      path: "/companies/:company_id/members",
      component: _c32519c8,
      name: "companies-company_id-members"
    }, {
      path: "/companies/:company_id/profile",
      component: _557a6bac,
      name: "companies-company_id-profile"
    }, {
      path: "/companies/:company_id/projects",
      component: _8dea566c,
      name: "companies-company_id-projects"
    }, {
      path: "/companies/:company_id/reports",
      component: _3110b476,
      name: "companies-company_id-reports"
    }, {
      path: "/companies/:company_id/setting",
      component: _439a44d3,
      name: "companies-company_id-setting"
    }, {
      path: "/companies/:company_id/sharing-contents",
      component: _7c777a0a,
      name: "companies-company_id-sharing-contents"
    }, {
      path: "/companies/:company_id/unavailability",
      component: _f6ebf67e,
      name: "companies-company_id-unavailability"
    }, {
      path: "/companies/:company_id/user-profile",
      component: _e8731898,
      name: "companies-company_id-user-profile"
    }, {
      path: "/companies/:company_id/projects/new",
      component: _2db15698,
      name: "companies-company_id-projects-new"
    }, {
      path: "/companies/:company_id/files/:id?",
      component: _ca84a8b0,
      name: "companies-company_id-files-id"
    }, {
      path: "/companies/:company_id/projects/:id",
      component: _19fb81b2,
      children: [{
        path: "",
        component: _51787ab5,
        name: "companies-company_id-projects-id"
      }, {
        path: "board_posts",
        component: _bfe220c0,
        name: "companies-company_id-projects-id-board_posts"
      }, {
        path: "detail",
        component: _75e9eb7e,
        name: "companies-company_id-projects-id-detail"
      }, {
        path: "member",
        component: _63be1147,
        name: "companies-company_id-projects-id-member"
      }, {
        path: "reports",
        component: _9303033c,
        name: "companies-company_id-projects-id-reports"
      }, {
        path: "schedule",
        component: _78223507,
        name: "companies-company_id-projects-id-schedule"
      }, {
        path: "board_posts/new",
        component: _278a2eee,
        name: "companies-company_id-projects-id-board_posts-new"
      }, {
        path: "board_posts/posts",
        component: _63e4f838,
        name: "companies-company_id-projects-id-board_posts-posts"
      }, {
        path: "schedule/fullscreen",
        component: _02aa3f54,
        name: "companies-company_id-projects-id-schedule-fullscreen"
      }, {
        path: "schedule/preview",
        component: _cb595c86,
        name: "companies-company_id-projects-id-schedule-preview"
      }, {
        path: "schedule/print",
        component: _1db0ce65,
        name: "companies-company_id-projects-id-schedule-print"
      }, {
        path: "schedule/view",
        component: _3ed441fa,
        name: "companies-company_id-projects-id-schedule-view"
      }, {
        path: "schedule/view/fullscreen",
        component: _11c3d84c,
        name: "companies-company_id-projects-id-schedule-view-fullscreen"
      }, {
        path: "board_posts/:board_id",
        component: _7dd57784,
        name: "companies-company_id-projects-id-board_posts-board_id"
      }, {
        path: "files/:file_id",
        component: _497cfcc7,
        name: "companies-company_id-projects-id-files-file_id"
      }, {
        path: "board_posts/:board_id/edit",
        component: _c250a970,
        name: "companies-company_id-projects-id-board_posts-board_id-edit"
      }, {
        path: "board_posts/:board_id/files",
        component: _7edcd26c,
        name: "companies-company_id-projects-id-board_posts-board_id-files"
      }, {
        path: "files/:file_id/edit",
        component: _6358f1a5,
        name: "companies-company_id-projects-id-files-file_id-edit"
      }, {
        path: "files/:file_id/preview",
        component: _ffc9ad06,
        name: "companies-company_id-projects-id-files-file_id-preview"
      }]
    }, {
      path: "/companies/:company_id/reports/:id",
      component: _2a6353a0,
      name: "companies-company_id-reports-id"
    }, {
      path: "/companies/:company_id/files/:id?/edit",
      component: _c0733ab8,
      name: "companies-company_id-files-id-edit"
    }, {
      path: "/companies/:company_id/files/:id?/preview",
      component: _f828b7c4,
      name: "companies-company_id-files-id-preview"
    }, {
      path: "/companies/:company_id/reports/:id/edit",
      component: _059b71c8,
      name: "companies-company_id-reports-id-edit"
    }, {
      path: "/companies/:company_id/reports/:id/pdf",
      component: _1c3a07d0,
      name: "companies-company_id-reports-id-pdf"
    }, {
      path: "/companies/:company_id/project/:id?/schedule/pdf",
      component: _d169711c,
      name: "companies-company_id-project-id-schedule-pdf"
    }, {
      path: "/",
      component: _26db085c,
      name: "index"
    }],

    fallback: false
  })
}
